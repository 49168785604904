<template>
    <div class="selling-goods">
        <el-table :data="sellingGoodsList" class="customTable" style="width: 100%; margin-top: 20px; flex: 1" height="1%">
            <el-table-column label="商品信息" min-width="400">
                <template slot-scope="scope">
                    <div class="" style="display: flex;margin: 10px 0;">
                        <span>商品编码：{{scope.row.goods_sku}}</span>
                        <span style="margin: 0 20px">所属类目：{{scope.row.goods_class_name}}</span>
                        <span>商品货号：{{scope.row.goods_no}}</span>
                    </div>
                    <div class="goods-wrapper">
                        <div class="goods-cover">
                            <img :src="scope.row.goods_img" alt="">
                        </div>
                        <div class="text-overflow-2 goods-name" :title="scope.row.good_title">{{scope.row.good_title}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="total_inventory" label="总库存" align="center"></el-table-column>
            <el-table-column prop="sale_price"  label="销售价" align="center"></el-table-column>
            <el-table-column prop="update_time" label="修改时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editBtn(scope.row)">修改库存</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" title="修改库存" @close="resetForm()"
                   custom-class="green-dialog02" :visible.sync="inventoryDialog" width="500px">
            <el-form :model="inventoryForm" ref="inventoryForm" label-position="right" label-width="100px">
                <el-form-item prop="scores_inventory" label="库存">
                     <el-input-number v-model="inventoryForm.scores_inventory"  :max="999999999" :precision="0" :controls="false"  placeholder="请输入正整数" style="width:340px"></el-input-number>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="inventoryDialog = false">取 消</el-button>
                <el-button type="primary" @click="inventoryBtn()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {transactionStuGoodsShow,transactionStuGoodsUpdate} from '@/utils/apis'
    export default {
        name: "StudentSellingGoods",
        data(){
            return{
                sellingGoodsList:[
                    {
                        goods_name:'',//商品名称
                        goods_sku:'',//商品编码
                        goods_class_name:'',//所属类目
                        goods_no:'',//商品货号
                        total_inventory:'',//总库存
                        sale_price:'',//销售价
                        update_time:'',//修改时间
                        scores_inventory:'',//	得分库存
                    }
                ],
                inventoryForm:{
                    good_id:'',
                    scores_inventory:'',
                },
                inventoryDialog:false,
            }
        },
        mounted() {
            this.getTransactionStuGoodsShow()
        },
        methods:{
            // 获取在售商品列表
            getTransactionStuGoodsShow(){
                let  param = {}
                if (this.$route.query.op_id) {
                    param.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    param.course_id = this.$route.query.course_id;
                }
                transactionStuGoodsShow(param).then((res) => {
                    this.sellingGoodsList=res.data
                })
            },
            //修改库存
            editBtn(row){
                this.inventoryDialog=true
                this.inventoryForm.good_id = row.good_id;
                this.inventoryForm.scores_inventory = row.scores_inventory;

            },
            inventoryBtn(){
                this.inventoryDialog = false;
                let params = {
                    good_id:this.inventoryForm.good_id,
                    scores_inventory:this.inventoryForm.scores_inventory
                }
                if (this.$route.query.op_id) {
                    params.op_id = this.$route.query.op_id;
                }
                if (this.$route.query.course_id) {
                    params.course_id = this.$route.query.course_id;
                }
                transactionStuGoodsUpdate(params).then((res) => {
                    this.$message.success(res.msg);
                    this.getTransactionStuGoodsShow()
                }).catch((err) => {
                    console.log(err);
                })
            },
            // 重置
            resetForm(){
                this.$refs.inventoryForm.resetFields();
                this.inventoryForm = this.$options.data().inventoryForm;
            },
        }
    }
</script>

<style scoped lang="scss">
    .selling-goods{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding:  0 20px;
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
        .goods-wrapper {
            display: flex;
            align-items: center;
            .goods-cover {
                width: 80px;
                height: 80px;
                display: flex;
                background: #F6F6F6;
                img  {
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }
            }
            .goods-name {
                width: 1%;
                flex: 1;
                margin-left: 10px;
                font-size: 16px;
            }
        }
        .dialog-footer {
            margin-top: 20px;
            text-align: center;
        }
    }

</style>